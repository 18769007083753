import React, { useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import styled from "styled-components"

const DefaultLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default function ObjectItemComponent({ desc, size }) {
  let [color, setColor] = useState("#000")
  return (
    <DefaultLoaderWrapper>
      <ClipLoader color={color} size={size} />
      {desc ? <div>{desc}</div> : ""}
    </DefaultLoaderWrapper>
  )
}
